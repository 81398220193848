/* eslint-disable func-names, no-new-wrappers */
import ErrorService from '@/service/utils/error';
import { debounceExecution } from '@/libs/utils';
import { generateUUIDv4 } from '@/libs/hash';

const TRANSLATION_DELAY = 500;

export default {
  data() {
    return {
      language: {
        request: { lang: '', text: '' },
        from: { lang: '', text: '', img: null },
        to: { lang: '', text: '' },
        autoTranslate: false,
        canDetect: false,
        detected: this.$consts.lang.DETECT,
        translateId: new String(generateUUIDv4()),
        selection: Object.keys(this.$consts.config.MT.LANG_PAIRS),
      },
    };
  },
  watch: {
    'language.detected': function (lang, prvlang) {
      if (lang === this.$consts.lang.DETECT) return;

      if (prvlang === lang) return;

      if (lang === this.language.to.lang || this.languagePairs.indexOf(this.language.to.lang) === -1) this.language.to.lang = this.languagePairs[0];

      if (this.language.autoTranslate) this.prePostLanguageTranslation(this.language.from.text);
    },
    'language.from.text': function (text) {
      if (!text) {
        this.language.from.img = null;
        this.language.to.text = '';
        return;
      }

      if (this.language.autoTranslate) this.prePostLanguageTranslation(text);
    },
    'language.from.lang': function (lang) {
      const isValidLang = lang !== this.$consts.lang.DETECT;
      const pairExists = this.languagePairs && this.languagePairs.indexOf(this.language.to.lang) !== -1;
      if (isValidLang) {
        if (!pairExists) this.language.to.lang = this.languagePairs[0];
        if (this.language.autoTranslate) this.prePostLanguageTranslation(this.language.from.text);
        return;
      }

      this.language.to.text = '';
    },
    'language.to.lang': function toLangChanged(toLang) {
      if (this.language.from.lang === this.$consts.lang.DETECT && this.language.detected === toLang) {
        this.language.to.text = this.language.from.text;
        return;
      }

      if (this.language.autoTranslate) this.prePostLanguageTranslation(this.language.from.text);
    },
  },
  computed: {
    languagePairs() {
      if (this.language.from.lang === this.$consts.lang.DETECT && this.language.detected === this.$consts.lang.DETECT) return null;

      if (this.language.from.lang === this.$consts.lang.DETECT) return this.$consts.config.MT.LANG_PAIRS[this.language.detected];

      return this.$consts.config.MT.LANG_PAIRS[this.language.from.lang];
    },
  },
  methods: {
    handleLanguageSelect(lang, isFrom) {
      if (isFrom) {
        if (this.language.from.lang === lang) return null;
        if (this.language.from.lang && this.language.to.lang === lang) return this.handleLanguageSwap();
        if (this.language.from.lang === this.$consts.lang.DETECT) this.language.detected = this.$consts.lang.DETECT;
        this.language.from.lang = lang;
      } else {
        if (this.language.to.lang === lang) return null;
        if (this.language.from.lang === lang) return this.handleLanguageSwap();
        this.language.to.lang = lang;
      }

      return null;
    },
    handleLanguageSwap() {
      if (!this.language.from.lang) return;

      const from = { ...this.language.from };
      const to = { ...this.language.to };
      if (!this.language.to.text) from.text = '';
      this.language.from = { ...to, img: from.img };
      const toLang = from.lang === this.$consts.lang.DETECT ? this.languagePairs[0] : from.lang;
      this.language.to = { ...from, lang: toLang };
    },
    prePostLanguageTranslation(text) {
      const { request, from, to } = this.language;

      if (!text || !from.text) return;

      if (from.lang === this.$consts.lang.DETECT && this.language.detected === this.$consts.lang.DETECT) return;

      const fromLanguage = from.lang === this.$consts.lang.DETECT ? this.language.detected : from.lang;

      if (to.lang === fromLanguage) return;

      if (request.text === text && request.lang === from) return;

      debounceExecution(this.language.translateId, () => this.postLanguageTranslation(fromLanguage, to.lang, text), TRANSLATION_DELAY);
    },
    async postLanguageTranslation(from, to, text) {
      try {
        this.language.request = { lang: from, text };
        const response = await this.$api.nlp.mt.postTranslate(from, to, [text]);
        if (response.data.error_msg) return ErrorService.displayErrorAlert(response.data.error_msg);
        const translation = response.data.map((data) => data.tgt);
        this.language.to.text = this.language.from.text.trim() === '' ? '' : `${translation.join('\n')}`;
      } catch (ex) {
        const message = ex.response.status === 404 ? 'Translatation pair not yet supported.' : 'Unable to process information';
        ErrorService.displayErrorAlert(message);
      } finally {
        this.language.request = { lang: '', text: '' };
      }
      return null;
    },
  },
};
