<template>
  <div class="language-options">
    <div class="flex flex-1 flex-center">
      <languages
        :on-select="(lang) => $props.onChange(lang, true)"
        :options="options"
        :disabled-options="{[$props.from]: true}"
        :value="actualFromLanguage"
        :label="$t(`placeholder['Translate from']`)"
        can-detect
      />
    </div>
    <button
      class="btn-swap"
      @click="$props.onSwap"
    >
      <img
        src="@/assets/images/icons/swap.svg"
        alt="swap"
      >
    </button>
    <div class="flex flex-1 flex-center">
      <languages
        :on-select="(lang) => $props.onChange(lang, false)"
        :options="pairs || options"
        :disabled-options="{[$props.to]: true}"
        :value="$props.to"
        :label="$t(`placeholder['Translate into']`)"
      />
    </div>
  </div>
</template>

<script>
import { constants } from '@/libs/constants';
import Languages from '@/views/pages/AIServices/MT/Languages.vue';

export default {
  components: { Languages },
  props: {
    onSwap: {
      type: Function,
      default: null,
    },
    onChange: {
      type: Function,
      default: null,
    },
    from: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    detected: {
      type: String,
      default: constants.lang.DETECT,
    },
    pairs: {
      type: Array,
      default: null,
    },
  },
  computed: {
    actualFromLanguage() {
      const { from, detected } = this.$props;

      if (from !== this.$consts.lang.DETECT) return from;

      if (detected === this.$consts.lang.DETECT) return detected;

      return `${from}-${detected}`;
    },
  },
};
</script>

<style lang="scss" scoped>
$swap-size: 0.32rem;

.language-options {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 0.56rem;

  .btn-swap {
    border: none;
    background: none;
    width: $swap-size;
    height: $swap-size;
  }
}
</style>
