<template>
  <div class="text-input">
    <button
      v-if="$props.value.length > 0"
      class="reset"
      @click="onReset"
    />
    <textarea
      :class="{ rtl: [$consts.lang.AR, $consts.lang.FA].includes($props.lang) }"
      class="text-input__textarea"
      type="textarea"
      rows="10"
      :maxlength="limit"
      :value="$props.value"
      :readonly="$props.readOnly"
      :placeholder="$props.placeholder"
      @input="(e) => $emit('change', e.target.value)"
    />
    <div
      v-if="!$props.hideKeyboard"
      class="text-input__footer"
    >
      <span class="text-input__footer__count">{{
        `${$props.value.length} / ${limit}`
      }}</span>
      <keyboard
        class="text-input__footer__keyboard"
        :value="value"
        :layout="keyboardLayout"
        @change="(value) => $emit('change', value)"
        @key-press="(value) => $emit('change', value)"
      />
    </div>
  </div>
</template>

<script>
import { constants } from '@/libs/constants';
import Keyboard from '@/components/Keyboard/Index.vue';

export default {
  components: { Keyboard },
  props: {
    lang: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    hideKeyboard: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: null,
    },
  },
  computed: {
    keyboardLayout() {
      return this.$props.lang || constants.lang.EN;
    },
  },
  methods: {
    onReset() {
      this.$emit('clear');
      this.$emit('change', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.text-input {
  position: relative;
  display: flex;
  flex: 1;
  border-bottom-left-radius: 4px;
  background: #ffffff;

  &__textarea {
    display: flex;
    flex: 1;
    border: none;
    outline: none;
    padding: 0.12rem;
    padding-right: 0.46rem;
    margin-bottom: 0.36rem;
    resize: none;
    font-size: 0.16rem;
    line-height: 0.24rem;
    font-family: Montserrat, sans-serif;
    color: $text-primary;

    &.rtl {
      font-family: Cairo, sans-serif;
    }
  }

  &__footer {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    bottom: 0.08rem;
    right: 0.2rem;

    &__count {
      margin-right: 0.12rem;
      font-size: 0.12rem;
      color: $text-tertiary;
    }
  }

  .reset {
    position: absolute;
    top: 0.12rem;
    right: 0.12rem;
    border: none;
    background: none;
    background-color: #db0000;
    border-radius: 50%;
    color: white;
    width: .19rem;
    height: .19rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "close";
      font-family: "Material Icons", sans-serif;
      font-size: 0.15rem;
      line-height: .19rem;
      font-weight: 900;
    }
  }
}
</style>
