<template>
  <div class="info">
    <div
      class="info__img-container"
      :class="$props.service"
    >
      <div class="wrapper">
        <img
          class="service-icon"
          :src="$props.icon"
          :alt="service"
        >
      </div>
    </div>
    <div class="info__content">
      <h5 class="header font-800">
        <slot name="header" />
      </h5>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    service: {
      type: String,
      required: true,
      validator: (value) => ['nlp', 'vca', 'aiu', 'asa', 'kg', 'mt', 'med', 'lg'].indexOf(value) !== -1,
    },
  },
};
</script>

<style lang="scss" scoped>
$header-colors: (
  "nlp": rgba(252, 156, 13, 0.2),
  "aiu": #E1EAFF,
  "asa": #ECFFFC,
  "vca": #F5F0FF,
  "kg": rgba(255, 66, 29, 0.21),
  "mt": #C7DAF4,
  "med": #FFECF5,
  "lg": #FCE9F9,
);

.info {
  display: flex;
  flex-direction: row;
  padding: 0.1rem 0;
  margin-bottom: 0.2rem;
  border-radius: $border-radius-service;
  background: $background-white;
  box-shadow: 0 0.1rem 0.18rem rgba(235, 237, 244, 0.76);

  &__img-container {
    position: relative;
    min-height: 1.23rem;
    margin-right: 0.33rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: calc(100% - 0.16rem);
      height: 100%;
      border-radius: 0 16px 16px 0;
    }

    @each $name, $color in $header-colors {
      &.#{$name}:before {
        background: $color;
      }
    }

    .wrapper {
      position: relative;
      width: 0.96rem;
      height: 100%;

      .service-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto -0.16rem auto 0;
      }
    }
  }

  &__content {
    margin-right: 0.2rem;
    display: flex;
    align-items: center;

    .header {
      color: $text-primary;
      font-size: .24rem;
    }

    .subheader {
      margin-top: 0.08rem;
      line-height: 0.2rem;
      color: $text-tertiary;
    }

    &__actions {
      display: flex;
      align-items: flex-end;
      margin-top: 0.16rem;

      :first-child {
        margin-right: 0.12rem;
      }
    }
  }
}
</style>
