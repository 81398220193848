<template>
  <el-tooltip
    manual
    :value="isActive"
    content="click to close tooltip function"
    placement="bottom"
    effect="light"
    popper-class="keyboard-container"
  >
    <template slot="content">
      <div class="simple-keyboard" />
    </template>
    <button
      class="keyboard-control"
      @click="isActive = !isActive"
    >
      <img
        :src="svg.KeyboardIcon"
        alt="Keyboard Icon"
      >
    </button>
  </el-tooltip>
</template>

<script>
import KeyboardIcon from '@/assets/images/icons/keyboard.svg';
import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';
import englishLayout from 'simple-keyboard-layouts/build/layouts/english';
import arabicLayout from 'simple-keyboard-layouts/build/layouts/arabic';
import germanLayout from 'simple-keyboard-layouts/build/layouts/german';
import frenchLayout from 'simple-keyboard-layouts/build/layouts/french';
import russianLayout from 'simple-keyboard-layouts/build/layouts/russian';
import hindiLayout from 'simple-keyboard-layouts/build/layouts/hindi';
import czechLayout from 'simple-keyboard-layouts/build/layouts/czech';
import chineseLayout from 'simple-keyboard-layouts/build/layouts/chinese';
import spanishLayout from 'simple-keyboard-layouts/build/layouts/spanish';
import turkishLayout from 'simple-keyboard-layouts/build/layouts/turkish';

export const keyboardLayouts = {
  '': englishLayout,
  en: englishLayout,
  ar: arabicLayout,
  de: germanLayout,
  fr: frenchLayout,
  ru: russianLayout,
  hi: hindiLayout,
  cz: czechLayout,
  zh: chineseLayout,
  es: spanishLayout,
  tu: turkishLayout,
};

/* eslint-disable prefer-const */
export default {
  name: 'SimpleKeyboard',
  props: {
    layout: {
      type: String,
      default: 'en',
    },
    value: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    keyboard: null,
    isActive: false,
  }),
  computed: {
    svg: () => ({ KeyboardIcon }),
  },
  watch: {
    '$props.value': function updateText(value) {
      if (!this.isActive) return;

      this.keyboard.setInput(value);
    },
    layout() {
      if (!this.isActive) return;

      this.createKeyboard();
    },
    isActive(value) {
      if (!value) return;

      this.createKeyboard();
    },
  },
  methods: {
    createKeyboard() {
      setTimeout(() => {
        const keyboard = new Keyboard({
          baseClass: this.baseClass,
          onChange: this.onChange,
          onKeyPress: this.onKeyPress,
          layout: keyboardLayouts[this.layout] || keyboardLayouts.en,
        });
        keyboard.setInput(this.$props.value);
        this.keyboard = keyboard;
      }, 300);
    },
    onChange(input) {
      this.$emit('change', input);
    },
    onKeyPress(button) {
      this.$emit('keyPress', button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === '{shift}' || button === '{lock}') this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
  },
};
</script>

<style lang="scss">
.keyboard-control {
  border: none;
  background: none;
}

.el-tooltip__popper.is-light.keyboard-container {
  width: 700px;
  border: none;
}
</style>
