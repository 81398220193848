<template>
  <div class="text-based">
    <language-selection
      :options="languages"
      :on-swap="handleLanguageSwap"
      :on-change="handleLanguageSelect"
      :pairs="languagePairs"
      :detected="language.detected"
      :from="language.from.lang"
      :to="language.to.lang"
    />
    <div class="text-based__inputs">
      <text-input
        :placeholder="inputPlaceholder"
        :lang="from"
        :value="language.from.text"
        :limit="5000"
        @change="handleChange"
      />
      <textarea
        v-model="language.to.text"
        :class="{ rtl: [$consts.lang.AR, $consts.lang.FA].includes(language.to.lang)}"
        class="text-result"
        type="textarea"
        rows="10"
        readonly
      />
    </div>
  </div>
</template>

<script>
import { generateUUIDv4 } from '@/libs/hash';
import { debounceExecution } from '@/libs/utils';
import languageMixin from './langugage.mixin';
import TextInput from './TextInput.vue';
import LanguageSelection from './LanguageSelection.vue';

const TRANSLATION_DELAY = 500;

/* eslint-disable no-new-wrappers, func-names */
export default {
  components: { LanguageSelection, TextInput },
  mixins: [languageMixin],
  data: () => ({
    detectId: new String(generateUUIDv4()),
    inputPlaceholder: '',
  }),
  computed: {
    languages() {
      return this.$consts
        .config
        .MT
        .AVAILABLE_LANG
        .filter((lang) => this.language.selection.indexOf(lang) !== -1 || lang === this.$consts.lang.DETECT);
    },
    from() {
      return this.language.from.lang === this.$consts.lang.DETECT ? this.language.detected : this.language.from.lang;
    },
  },
  watch: {
    'language.from.lang': function (lang) {
      const DETECT_KEY = this.$consts.lang.DETECT;

      this.getLangPlaceholder(lang);

      if (lang !== DETECT_KEY) return;

      if (this.language.detected && this.language.detected !== DETECT_KEY) return;

      if (!this.language.from.text) return;

      this.handleLanguageDetection(this.language.from.text);
    },
  },
  mounted() {
    this.language.from.lang = this.$consts.lang.DETECT;
    this.language.to.lang = this.$consts.lang.AR;
    this.language.autoTranslate = true;
  },
  methods: {
    async getLangPlaceholder(lang) {
      const defaultPlaceholder = 'Type something';

      try {
        if ([this.$consts.lang.EN, this.$consts.lang.DETECT].includes(lang)) throw Error();

        const { data: [{ tgt }] } = await this.$api.nlp.mt.postTranslate(this.$consts.lang.EN, lang, [defaultPlaceholder]);
        this.inputPlaceholder = tgt;
      } catch (error) {
        this.inputPlaceholder = defaultPlaceholder;
      }
    },
    handleChange(text) {
      if (this.language.from.lang === this.$consts.lang.DETECT) debounceExecution(this.detectId, () => this.handleLanguageDetection(text), TRANSLATION_DELAY);

      this.language.from.text = text;
    },
    async handleLanguageDetection(text) {
      if (!text) return;

      const { data } = await this.$api.nlp.mt.postLanguageDetection(text);

      if (data.lang === this.language.detected) return;

      if (this.languages.indexOf(data.lang) === -1) return;

      this.language.detected = data.lang;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-based {
  display: flex;
  flex-direction: column;
  border: 1px solid #D8DDED;
  border-radius: 4px;

  &__inputs {
    display: grid;
    @include grid-cols-2;
    gap: 1px;
    padding-top: 1px;
    background: #D8DDED;

    @include mobile {
      @include grid-cols-1;
      .text-input {
        border-radius: 0;
      }
      .text-result {
        border-radius: 0 0 4px 4px;
      }
    }
  }
}
</style>
