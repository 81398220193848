<template>
  <div class="service-template">
    <p
      v-if="isAuthenticated()"
      class="service-template__welcome"
    >
      {{ $t('services.welcome') }}, {{ this.$store.state.authUser.nickName || this.$store.state.authUser.username }}
    </p>
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
};
</script>
<style lang="scss" scoped>
.service-template {
  background: $background-secondary;
  padding: 0.42rem;
  @include tablet-and-less {
    padding: 0.2rem;
  }
  &.nlp-example-rtl {
    /deep/.el-textarea, /deep/.el-select, /deep/.el-input, /deep/.result, /deep/.el-select-dropdown__list, /deep/.el-input__inner {
      direction: rtl;
      font-family: Cairo, sans-serif;
    }
  }

  &__welcome {
    text-transform: capitalize;
    margin-bottom: .18rem;
    font-weight: 600;
    font-size: .18rem;
    line-height: .2rem;
    color: #3A3955;
    word-spacing: .02rem;
  }
}
</style>
