<template>
  <div>
    <div class="image-based">
      <language-selection
        :options="languages"
        :on-swap="handleLanguageSwap"
        :on-change="handleLanguageSelect"
        :pairs="languagePairs"
        :detected="language.detected"
        :from="language.from.lang"
        :to="language.to.lang"
      />
      <div :class="{'image-based__inputs': true, 'has-image': language.from.img}">
        <optical-character-recognition
          :class="{ hidden: !fromIsEmpty }"
          :lang="language.from.lang"
          :file="blob"
          :disabled="languages.indexOf(language.from.lang) === -1"
          @upload="(file) => blob = file"
          @change="handleChange"
        />
        <text-input
          v-if="!fromIsEmpty"
          :lang="language.from.lang"
          :value="language.from.text"
          :limit="5000"
          :read-only="fromIsEmpty"
          @change="handleChange"
        />
        <el-image
          v-if="img"
          :src="img"
          :preview-src-list="[img]"
          :z-index="9001"
        />
        <textarea
          v-model="language.to.text"
          :class="{ rtl: language.to.lang === $consts.lang.AR }"
          class="text-result"
          type="textarea"
          name="to-values"
          rows="10"
          readonly
        />
      </div>
    </div>
    <service-message-bar
      v-if="infoMessage"
      class="image-based__message"
      :type="infoMessage.type"
      :message="infoMessage.message"
    />
  </div>
</template>

<script>
import ServiceMessageBar from '@/views/components/ServiceMessageBar/Index.vue';
import OpticalCharacterRecognition from './OpticalCharacterRecognition.vue';
import LanguageSelection from './LanguageSelection.vue';
import languageMixin from './langugage.mixin';
import TextInput from './TextInput.vue';

/* eslint-disable no-return-assign */
export default {
  components: {
    OpticalCharacterRecognition, LanguageSelection, TextInput, ServiceMessageBar,
  },
  mixins: [languageMixin],
  data: () => ({
    blob: null,
    infoMessage: null,
  }),
  computed: {
    languages() {
      return this.$consts
        .config
        .OCR
        .AVAILABLE_LANG
        .filter((lang) => this.language.selection.indexOf(lang) !== -1);
    },
    img() {
      if (!this.language.from.img) return null;

      return `data:image/png;base64,${this.language.from.img}`;
    },
    fromIsEmpty() {
      return this.language.from.text.length === 0;
    },
  },
  mounted() {
    this.language.from.lang = this.$consts.lang.EN;
    this.language.to.lang = this.$consts.lang.AR;
    this.language.autoTranslate = true;
  },
  methods: {
    handleChange(text, img) {
      if (!text) {
        this.blob = null;
        this.infoMessage = null;
      } else {
        this.infoMessage = { type: 'success', message: 'You can Add / Edit the text' };
      }

      if (img) this.language.from.img = img;
      return this.language.from.text = text;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-based {
  display: flex;
  flex-direction: column;
  border: 1px solid #D8DDED;
  border-radius: 4px;

  &__inputs {
    display: grid;
    @include grid-cols-2;
    gap: 1px;
    grid-auto-rows: 1fr;
    padding-top: 1px;
    background: #D8DDED;

    &.has-image {
      @include grid-cols-3
    }

    &__mic {
      position: relative;

      &__asr {
        position: absolute;
        left: 0.16rem;
        bottom: 0.16rem;
      }
    }

    @include mobile {
      @include grid-cols-1;
      .text-result {
        border-radius: 0 0 4px 4px;
      }
    }
  }

  &__message {
    width: 33.33%;
  }
}

.hidden {
  display: none;
}
</style>
