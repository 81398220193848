<template>
  <div @click="handleClick">
    <el-upload
      v-loading="isLoading"
      class="upload-file"
      action=""
      drag
      :accept="formats"
      :on-change="(upload) => handleChange(upload)"
      :auto-upload="false"
      :disabled="disabled"
    >
      <div class="upload-content">
        <img
          :src="require('@/assets/images/icons/upload.svg')"
          class="upload-content__icon"
          alt="Upload"
        >
        <span class="upload-content__label">{{ uploadLabel }}</span>
      </div>
      <div class="upload-info">
        <p class="upload-info__label">
          *{{ $t(`placeholder['Image file size should be less than 5 MB']`) }}
        </p>
      </div>
    </el-upload>
  </div>
</template>

<script>
/* eslint-disable no-return-assign */
import ErrorService from '@/service/utils/error';

export default {
  props: {
    lang: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    file: {
      type: File,
      default: null,
    },
  },
  data: () => ({
    success: false,
    isLoading: false,
  }),
  computed: {
    uploadLabel() {
      if (!this.isLoading) return 'Upload image for translation';

      return 'Uploading file for translation';
    },
    formats() {
      return this.$consts.config.OCR.ALLOWED_FORMATS.join(',');
    },
  },
  watch: {
    lang() {
      if (!this.file) return;

      this.handleOCR();
    },
    file(file) {
      if (!file) return;

      this.handleOCR();
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) return;

      this.$message({
        message: 'Selected Language for OCR is currently not supported',
        type: 'error',
      });
    },
    async handleChange(upload) {
      if (!upload || !upload.raw) return null;

      this.success = false;

      const file = upload.raw;

      if (this.formats.split(',').indexOf(file.type) === -1) return ErrorService.displayErrorAlert('File format not allowed!');

      return this.$emit('upload', file);
    },
    handleOCR() {
      if (!this.file) return null;

      if (this.success && this.lang === 'en') return null;

      const imageFormats = this.$consts.config.OCR.ALLOWED_FORMATS;

      if (imageFormats.indexOf(this.file.type) !== -1) return this.handleImage(this.file);

      return this.handleTxt(this.file);
    },
    async handleTxt(file) {
      const MAX_TXT_SIZE_MB = 1024 * 1024 * 2;
      if (file.size > MAX_TXT_SIZE_MB) return ErrorService.displayErrorAlert(this.$t('placeholder.maxTxtExceeded'));

      this.isLoading = true;
      const text = await file.text();
      this.checkAccuracy(text);
      this.$emit('change', text);
      return this.isLoading = false;
    },
    async handleImage(file) {
      if (this.$consts.config.OCR.AVAILABLE_LANG.indexOf(this.lang) === -1) return null;

      const MAX_IMAGE_SIZE_MB = 1024 * 1024 * 5;
      if (file.size > MAX_IMAGE_SIZE_MB) return ErrorService.displayErrorAlert(this.$t('placeholder.maxImageExceeded'));

      this.isLoading = true;
      const formData = new FormData();
      formData.append('file', file);

      const { data } = await this.$api.aiu.ocr.postImage(formData, { lang: this.$props.lang });
      const text = data.result.map((result) => result.text).join('\n');

      if (data && data.result && data.result.length > 0) this.$emit('change', text, data.image);

      this.isLoading = false;
      return this.checkAccuracy(text);
    },
    async checkAccuracy(text) {
      if (this.success) return;

      const response = await this.$api.nlp.mt.postLanguageDetection(text);

      if (response.data.lang.toLowerCase() === this.lang.toLowerCase()) this.success = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-file {
  display: flex;
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  font-family: Montserrat, sans-serif;

  > .el-upload.el-upload--text > .el-upload-dragger {
    width: 100%;
    height: 100%;
  }

  .upload-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    width: 100%;

    &__label {
      margin-top: 0.04rem;
      color: $text-primary;
      font-size: 0.14rem;
      line-height: 0.16rem;
    }

    $icon-size: 0.64rem;
    &__icon {
      width: $icon-size;
      height: $icon-size;
    }
  }

  .upload-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__label {
      color: $text-primary-dimmed;
      font-size: 0.12rem;
    }
  }
}
</style>
