<template>
  <div
    class="service-message"
    :class="type"
  >
    <i
      v-if="type == 'error'"
      class="material-icons-outlined"
    >report_problem</i>
    {{ message }}
  </div>
</template>

<script>
export default {
  name: 'ServiceMessageBar',

  props: {
    type: {
      type: String,
      default: 'success',
      required: true,
      validator: (value) => ['success', 'error', 'info'].indexOf(value) !== -1,
    },
    message: {
      type: String,
      required: true,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.service-message {
  margin-top: 0.06rem;
  font-size: 0.13rem;
  line-height: 0.19rem;
  text-align: center;
  display: flex;
  padding: 0 0.15rem;
  justify-content: center;
  align-items: center;
  font-weight: 600;

  i {
    font-size: 0.2rem;
    line-height: 0.2rem;
    margin-left: 0.03rem;
    margin-right: 0.03rem;
  }

  &.error {
    color: $color-white;
    background-color: #db0000;
    border-radius: 22px;
    height: 0.24rem;
  }

  &.success {
    color: black;
    background-color: #C1E693;
    border-radius: 3px;
    height: 0.32rem;
  }
}
</style>
