<template>
  <el-dropdown
    trigger="click"
    @command="$props.onSelect"
  >
    <span class="el-dropdown-link">
      <span class="el-dropdown-link__label">{{ actionLabel }}
        <span class="option">
          {{ dropdownLabel }}
        </span>
      </span>
      <img
        :src="svg.ArrowDown"
        alt="ArrowDown"
        class="el-dropdown-link__arrow"
      >
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="option in actualOptions"
        :key="option"
        :disabled="disabledOptions[option]"
        :command="option"
      >
        {{ $t(`languages['${option}']`) }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import ArrowDown from '@/assets/images/icons/language-arrow-down.svg';

export default {
  props: {
    disabledOptions: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: String,
      default: '',
    },
    onSelect: {
      type: Function,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    canDetect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    svg: () => ({ ArrowDown }),
    actualOptions() {
      if (this.canDetect) return this.options;

      return this.options.filter((option) => option !== this.$consts.lang.DETECT);
    },
    actionLabel() {
      const { label } = this.$props;

      if (!label) return '';

      return `${label}: `;
    },
    dropdownLabel() {
      const result = this.$props.value.split('-');

      if (!result[0]) return '';

      if (result[0] !== this.$consts.lang.DETECT) return this.$t(`languages['${result[0]}']`);

      if (result.length === 1) return this.$t("placeholder['Detect Language']");

      return `${this.$t("placeholder['Detect Language']")} - ${this.$t(`languages['${result[1]}']`)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &__label {
    font-size: 0.14rem;
    font-weight: 500;
    color: $text-primary;
    line-height: 147.1%;
    text-align: center;

    @include mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  $icon-size: 0.24rem;

  &__arrow {
    width: $icon-size;
    height: $icon-size;
  }

  .option {
    color: $text-blue;
  }
}
</style>
