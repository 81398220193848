import { sha256 } from 'js-sha256';

export const generateSignatureSHA256 = (secret, message) => sha256.hmac(secret, message);

// eslint-disable-next-line no-bitwise, no-mixed-operators
export const generateUUIDv4 = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));

export default {
  generateUUIDv4,
  generateSignatureSHA256,
};
