<template>
  <div class="step-progress">
    <div
      v-for="(step, index) in steps"
      :key="index"
      class="step-progress__step"
    >
      <div class="step-progress__step-text">
        <span class="step-progress__step__label">{{ step.label }}</span>
        <span
          class="step-progress__step__title"
          :class="{ completed: step.status === 100 }"
        >{{ step.title }}</span>
      </div>

      <div class="step-progress__step__progress">
        <div
          class="step-progress__step__progress__indicator"
          :style="{ width: step.status + '%' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepProgressBar',

  props: {
    steps: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.step-progress {
  display: flex;
  flex: 1;

  &__step {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-left: 1px solid #fc9c0d;

    &-text {
      padding-left: 0.05rem;
      display: flex;
      flex-direction: column;
    }

    &__label {
      font-weight: 500;
      font-size: 0.1rem;
      line-height: 0.15rem;
      color: #d5830b;
      text-transform: uppercase;
    }

    &__title {
      font-weight: 500;
      font-size: 0.1rem;
      line-height: 0.15rem;
      color: #000000;
      text-transform: capitalize;
      margin-bottom: 0.07rem;

      &.completed {
        color: #d4830b;
      }
    }

    &__progress {
      height: 0.09rem;
      background: #c4c4c4;
      position: relative;
      overflow: hidden;
      margin-top: auto;

      &__indicator {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: #fc9c0d;
        transition: width 0.5s;
      }
    }

    &:first-child {
      .step-progress__step {
        &__progress {
          border-top-left-radius: 51px;
          border-bottom-left-radius: 51px;
        }
      }
    }

    &:last-child {
      .step-progress__step {
        &__progress {
          border-top-right-radius: 51px;
          border-bottom-right-radius: 51px;
        }
      }
    }
  }
}
</style>
