<template>
  <service-template-container>
    <service-template-info
      :icon="require('@/assets/images/icons/services/header/MT.svg')"
      service="mt"
    >
      <template slot="header">
        {{ $t(`title['Machine Translation']`) }}
      </template>
    </service-template-info>
    <div class="mt">
      <div class="mt__options">
        <input-option
          :label="$t(`services.machineTranslation.Text`)"
          :is-active="isActive(options.text)"
          :icon="svg.OptionText"
          :option="options.text"
          :on-click="() => selected = options.text"
        />
        <input-option
          :label="$t(`services.machineTranslation.Image`)"
          :is-active="isActive(options.image)"
          :icon="svg.OptionImage"
          :option="options.image"
          :on-click="() => selected = options.image"
        />
        <input-option
          :label="$t(`services.machineTranslation.Document`)"
          :is-active="isActive(options.document)"
          :icon="svg.OptionImage"
          :option="options.document"
          :on-click="() => selected = options.document"
        />
        <input-option
          :label="$t(`services.machineTranslation.Audio`)"
          :is-active="isActive(options.audio)"
          :icon="svg.OptionAudio"
          :option="options.audio"
          :on-click="() => selected = options.audio"
        />
      </div>
      <div class="mt__options__content">
        <text-based v-if="isActive(options.text)" />
        <audio-based v-if="isActive(options.audio)" />
        <image-based v-if="isActive(options.image)" />
        <document-based v-if="isActive(options.document)" />
      </div>
    </div>
  </service-template-container>
</template>

<script>
import ServiceTemplateContainer from '@/views/pages/AIServices/components/ServiceTemplate/Container.vue';
import ServiceTemplateInfo from '@/views/pages/AIServices/components/ServiceTemplate/Info.vue';
import OptionText from '@/assets/images/icons/mt-option-text.svg';
import OptionAudio from '@/assets/images/icons/mt-option-audio.svg';
import OptionImage from '@/assets/images/icons/mt-option-upload.svg';
import InputOption from './InputOption.vue';
import TextBased from './TextBased.vue';
import AudioBased from './AudioBased.vue';
import ImageBased from './ImageBased.vue';
import DocumentBased from './DocumentBased.vue';

/* eslint-disable no-new-wrappers */
export default {
  components: {
    ServiceTemplateContainer,
    ServiceTemplateInfo,
    InputOption,
    TextBased,
    AudioBased,
    ImageBased,
    DocumentBased,
  },
  data: () => ({ selected: null }),
  computed: {
    svg: () => ({
      OptionText,
      OptionAudio,
      OptionImage,
    }),
    options: () => ({
      text: 'text',
      audio: 'audio',
      image: 'image',
      document: 'document',
    }),
  },
  mounted() {
    this.selected = this.options.text;
  },
  methods: {
    isActive(toCheck) {
      return this.options[this.selected] === toCheck;
    },
  },
};
</script>

<style lang="scss" scoped>
.mt {
  display: flex;
  flex-direction: column;

  &__options {
    display: flex;
    flex-direction: row;
    height: 0.56rem;
    padding: 0.08rem 0.16rem;
    border-radius: 4px;
    background: $background-white;

    > * {
      margin-right: 0.32rem;
    }

    :last-child {
      margin-right: 0;
    }

    &__content {
      margin-top: 0.16rem;
    }
  }
}

::v-deep {
  .text-result {
    resize: none;
    border: none;
    outline: none;
    padding: 0.12rem;
    border-bottom-right-radius: 4px;
    font-size: 0.16rem;
    line-height: 0.24rem;
    font-family: Montserrat, sans-serif;
    color: $text-primary;

    &.rtl {
      font-family: Cairo, sans-serif;
    }
  }
}
</style>
