<template>
  <div>
    <div class="audio-based">
      <language-selection
        :options="languages"
        :on-swap="handleLanguageSwap"
        :on-change="handleLanguageSelect"
        :pairs="languagePairs"
        :detected="language.detected"
        :from="language.from.lang"
        :to="language.to.lang"
      />
      <div class="audio-based__inputs">
        <div class="audio-based__inputs__mic">
          <text-input
            :placeholder="$t('placeholder.audioMT', [maxFileUploadSize])"
            :lang="language.from.lang"
            :value="language.from.text"
            :limit="5000"
            :read-only="language.from.text.length === 0"
            @change="handleChange"
            @clear="handleTextClear"
          />
          <automatic-speech-recognition
            ref="asrComponent"
            :on-response="handleChange"
            :lang="language.from.lang"
            class="audio-based__inputs__mic__asr"
            :show-file-upload="true"
            :file-max-size="10"
            @error="onASRError"
          />
        </div>
        <textarea
          v-model="language.to.text"
          :class="{ rtl: language.to.lang === $consts.lang.AR }"
          class="text-result"
          type="textarea"
          name="to-values"
          rows="10"
          readonly
        />
      </div>
    </div>
    <service-message-bar
      v-if="serviceError"
      class="audio-based__message"
      :type="serviceError.type"
      :message="serviceError.message"
    />
  </div>
</template>

<script>
import AutomaticSpeechRecognition from '@/views/pages/AIServices/ASA/AutomaticSpeechRecognition/AudioStream.vue';
import ServiceMessageBar from '@/views/components/ServiceMessageBar/Index.vue';
import LanguageSelection from './LanguageSelection.vue';
import languageMixin from './langugage.mixin';
import TextInput from './TextInput.vue';

export default {
  components: {
    AutomaticSpeechRecognition, LanguageSelection, TextInput, ServiceMessageBar,
  },
  mixins: [languageMixin],
  data() {
    return {
      maxFileUploadSize: 10,
      serviceError: null,
    };
  },
  computed: {
    languages() {
      return this.$consts
        .config
        .ASR
        .AVAILABLE_LANG
        .filter((lang) => this.language.selection.indexOf(lang) !== -1);
    },
  },
  mounted() {
    this.language.from.lang = this.$consts.lang.AR;
    this.language.to.lang = this.$consts.lang.EN;
    this.language.autoTranslate = true;
  },
  methods: {
    handleChange(text) {
      this.language.from.text = text;
      this.serviceError = null;
    },
    handleTextClear() {
      this.$refs.asrComponent.clearPreviousStream();
    },
    onASRError(er) {
      this.serviceError = er;
    },
  },
};
</script>

<style lang="scss" scoped>
.audio-based {
  display: flex;
  flex-direction: column;
  border: 1px solid #D8DDED;
  border-radius: 4px;

  &__inputs {
    display: grid;
    @include grid-cols-2;
    gap: 1px;
    padding-top: 1px;
    background: #D8DDED;

    &__mic {
      position: relative;

      &__asr {
        position: absolute;
        left: 0.16rem;
        bottom: 0.16rem;
        width: 70%;
      }
    }

    @include mobile {
      @include grid-cols-1;
      .text-input {
        border-radius: 0;
      }
      .text-result {
        border-radius: 0 0 4px 4px;
      }
    }
  }

  &__message {
    width: 50%;
  }
}
</style>
