<template>
  <div>
    <div class="document-based">
      <language-selection
        :options="languages"
        :on-swap="handleLanguageSwap"
        :on-change="handleLanguageSelect"
        :pairs="languagePairs"
        :detected="language.detected"
        :from="language.from.lang"
        :to="language.to.lang"
      />
      <div class="document-based__inputs">
        <div class="document-based__inputs__content">
          <div class="document-based__inputs__content__placeholder">
            <button
              v-if="file !== null"
              class="reset"
              @click="handleCancel"
            />
            <el-upload
              class="document-upload"
              action=""
              :accept="formats"
              :on-change="handleUpload"
              :auto-upload="false"
              :disabled="translateProgress.status > 0"
              drag
            >
              <div class="flex flex-center">
                <img
                  :src="require('@/assets/images/icons/upload.svg')"
                  class="document-upload__icon"
                  alt="Upload"
                >
              </div>
              <div
                v-if="!file"
                class="document-upload__content"
              >
                <span>{{ $t('services.machineTranslation.documentBased.supported') }}</span>
                <span class="document-upload__content__secondary">({{ $t('services.machineTranslation.documentBased.maxFileSize', [maxFileSize]) }})</span>
              </div>
              <div
                v-else
                class="document-upload__info flex flex-column"
              >
                <span class="document-upload__info__label">{{ $t('services.machineTranslation.documentBased.filename') }}: <span class="truncate">{{ file.name }}</span></span>
                <span class="document-upload__info__label">{{ $t('services.machineTranslation.documentBased.filesize') }}: <span>{{ file.size }}</span></span>
                <span class="document-upload__info__label">{{ $t('services.machineTranslation.documentBased.filetype') }}: <span class="truncate">{{ file.type }}</span></span>
              </div>
            </el-upload>
          </div>
          <div class="document-based__inputs__content__actions">
            <step-progress-bar :steps="progressStatus" />

            <div class="document-based__inputs__content__actions__btns">
              <div class="flex flex-1">
                <el-button
                  type="primary"
                  class="document-based__inputs__content__actions-btn"
                  :disabled="translateProgress.status > 0 || file === null"
                  @click="handleTranslate"
                >
                  Translate
                </el-button>
                <el-button
                  type="danger"
                  class="document-based__inputs__content__actions-btn"
                  :disabled="interval === null || translateProgress.status === 100"
                  @click="handleCancel"
                >
                  Cancel
                </el-button>
              </div>
              <el-button
                type="success"
                class="document-based__inputs__content__actions-btn"
                :disabled="translatedFile === null"
                @click="handleDownload"
              >
                Download
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <service-message-bar
      v-if="infoMessage"
      class="document-based__message"
      :type="infoMessage.type"
      :message="infoMessage.message"
    />
  </div>
</template>

<script>
import { humanFileSize } from '@/libs/utils';
import ServiceMessageBar from '@/views/components/ServiceMessageBar/Index.vue';
import StepProgressBar from '@/views/components/StepProgressBar/Index.vue';
import languageMixin from './langugage.mixin';
import LanguageSelection from './LanguageSelection.vue';

const STATUS_INTERVAL_MS = 1000;

/* eslint-disable no-new-wrappers, func-names, no-return-assign */
export default {
  components: { LanguageSelection, StepProgressBar, ServiceMessageBar },
  mixins: [languageMixin],
  data: () => ({
    file: null,
    uploadProgress: { },
    translateProgress: { },
    downloadProgress: { },
    interval: null,
    translatedFile: null,
    isChecking: false,
    maxFileSize: 5,
    infoMessage: null,
    limitLanguage: [],
  }),
  computed: {
    progressStatus() {
      return [{ ...this.uploadProgress, label: 'step 1' }, { ...this.translateProgress, label: 'step 2' }, { ...this.downloadProgress, label: 'step 3' }];
    },
    languages() {
      if (this.limitLanguage.length > 0) {
        return this.limitLanguage;
      }
      return this.$consts
        .config
        .PARSER
        .AVAILABLE_LANG
        .filter((lang) => this.language.selection.indexOf(lang) !== -1);
    },
    format: () => ({
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word Document',
      'application/pdf': 'PDF Document',
      'text/plain': 'Txt File',
      'application/vnd.ms-powerpoint': 'Powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'Powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow': 'Powerpoint',
      '.csv': 'CSV',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel',
      'application/vnd.ms-excel': 'Excel',
    }),
    formats() {
      return Object.keys(this.format).join(',');
    },
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);

    this.interval = null;
  },
  mounted() {
    this.language.from.lang = this.$consts.lang.EN;
    this.language.to.lang = this.$consts.lang.AR;
    this.resetProgressStatus();
  },
  methods: {
    resetProgressStatus() {
      this.uploadProgress = {
        title: 'upload document',
        status: 0,
      };
      this.translateProgress = {
        title: 'translate document',
        status: 0,
      };
      this.downloadProgress = {
        title: 'download document',
        status: 0,
      };
    },

    handleUpload(upload) {
      if (this.formats.split(',').indexOf(upload.raw.type) === -1) {
        this.infoMessage = { type: 'error', message: 'File format not allowed!' };
        this.handleCancel();
        return false;
      } if ((upload.size / 1024 / 1024) > this.maxFileSize) {
        this.infoMessage = { type: 'error', message: `File size can not exceed ${this.maxFileSize}MB!` };
        this.handleCancel();
        return false;
      }
      if (upload.raw.type.indexOf('pdf') > -1) {
        this.limitLanguage = ['en', 'ar'];
        if (this.limitLanguage.indexOf(this.language.from.lang) === -1) {
          this.$message.error('PDF only support translation between English and Arabic.');
          this.language.from.lang = 'en';
          this.handleCancel();
          return false;
        }
      }
      this.resetProgressStatus();
      this.translatedFile = null;
      this.infoMessage = null;

      this.uploadProgress.status = 100;
      this.uploadProgress.title = 'Successfully uploaded';

      if (this.interval) clearInterval(this.interval);
      this.interval = null;
      this.isChecking = false;
      return this.file = {
        blob: upload.raw,
        name: upload.name,
        type: this.format[upload.raw.type],
        size: humanFileSize(upload.size, true),
      };
    },
    handleCancel() {
      if (this.interval) clearInterval(this.interval);
      this.interval = null;
      this.file = null;
      this.resetProgressStatus();
      this.translatedFile = null;
      this.limitLanguage = [];
    },
    async handleTranslate() {
      if (!this.file) return;
      const formData = new FormData();
      formData.append('upload_file', this.file.blob);
      formData.append('from_lng', this.language.from.lang);
      formData.append('to_lng', this.language.to.lang);
      formData.append('callback', '');
      formData.append('use_callback', false);
      formData.append('use_ocr', this.file.type.indexOf('PDF') > -1);

      // Update translate progress status
      this.translateProgress.status = 0;
      this.translateProgress.title = 'Translation in progress...';

      try {
        const { data } = await this.$api.nlp.mt.postDocumentParsing(formData);
        if (!this.file) return;
        this.interval = setInterval(() => this.handleStatusCheck(data.body.id), STATUS_INTERVAL_MS);
      } catch (ex) {
        this.resetProgressStatus();
      }
    },
    async handleStatusCheck(taskId) {
      if (this.isChecking) return;

      this.isChecking = true;

      try {
        const { data: { body: progress } } = await this.$api.nlp.mt.postDocumentParsingStatus(taskId);

        const { status } = progress;

        if (status && typeof status !== 'object') {
          this.translateProgress.status = 0;
        } else if (status.done) {
          this.translateProgress.status = status.done;
        } else if (status.body.link) {
          this.translateProgress.status = 100;
          this.translateProgress.title = 'Translation Completed. Ready for Download';

          this.translatedFile = status.body.link;
        }

        if (!this.translateProgress.status || !this.translatedFile) return;
        if (this.interval) clearInterval(this.interval);

        this.interval = null;
      } catch (ex) {
        // alert some error
      } finally {
        this.isChecking = false;
      }
    },
    handleDownload() {
      if (!this.file || !this.translatedFile) return;

      window.open(this.translatedFile, '_blank');
      this.downloadProgress.status = 100;
      this.downloadProgress.title = 'Document downloaded';
    },
  },
};
</script>

<style lang="scss" scoped>
.document-based {
  display: flex;
  flex-direction: column;
  border: 1px solid #D8DDED;
  border-radius: 4px;

  &__inputs {
    border-top: 1px solid #D8DDED;

    &__content {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 1px;

      @include mobile {
        flex-direction: column-reverse;
      }

      &__actions {
        display: flex;
        flex-direction: column;
        padding: .12rem .29rem;

        &-btn {
          &.el-button {
            font-size: 0.16rem;
            margin: .07rem ;
            width: 100%;

            & + .el-button {
              margin: .07rem ;
            }

            &:last-child:not(:first-child) {
              margin-right: 0;
            }
          }
        }

        &__btns {
          padding: .32rem;

        }
        @include mobile {
          @include grid-cols-3;
          width: 100%;
          margin-top: 0.16rem;
        }
      }

      &__placeholder {
        display: flex;
        flex: 1;
        position: relative;

        @include mobile {
          margin-left: 0;
        }
      }
    }
  }

  &__message {
    width: 50%;
  }
}

.document-upload {
  display: flex;
  flex: 1;

  &__content {
    color: $text-primary;
    font-size: .12rem;
    line-height: .23rem;
    display: flex;
    flex-direction: column;

    &__secondary {
      font-size: .1rem;
    }
  }

  &__info {
    margin-top: .1rem;

    &__label {
      color: $text-primary;
      font-size: .12rem;
      line-height: .22rem;
      word-spacing: 1px;

      span {
        margin-left: .04rem;
      }
    }
  }
}

.el-button+.el-button {
  margin: 0;
}

  .reset {
    position: absolute;
    top: 0.12rem;
    right: 0.12rem;
    border: none;
    background: none;
    background-color: #db0000;
    border-radius: 50%;
    color: white;
    width: .19rem;
    height: .19rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &::after {
      content: "close";
      font-family: "Material Icons", sans-serif;
      font-size: 0.15rem;
      line-height: .19rem;
      font-weight: 900;
    }
  }
</style>

<style lang="scss">
.document-upload {
  .el-upload {
    display: flex;
    flex: 1;

    &-dragger {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &-list {
      display: none;
    }
  }
}
</style>
