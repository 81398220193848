<template>
  <button
    class="btn-option flex-center"
    :class="classes"
    @click="$props.onClick"
  >
    <img
      :src="$props.icon"
      :alt="$props.option"
      class="btn-option__icon"
    ><span class="btn-option__label font-500">{{ $props.label }}</span>
  </button>
</template>

<script>
export const inputOptions = ['text', 'audio', 'image', 'document'];

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    option: {
      type: String,
      required: true,
      validator: (value) => inputOptions.indexOf(value) !== -1,
    },
    label: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      default: null,
    },
  },
  computed: {
    classes() {
      const { isActive, option } = this.$props;

      return { active: isActive, [option]: option };
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-option {
  display: flex;
  justify-content: flex-start;
  width: 1.56rem;
  padding: 0.16rem;
  border: none;
  background: none;

  &.active {
    border-radius: 4px;
  }

  &.text.active {
    content: '';
    background: $background-green-light;
  }

  &.audio.active {
    content: '';
    background: $background-blue-light;
  }

  &.image.active {
    content: '';
    background: $background-orange-light;
  }

  &.document.active {
    content: '';
    background: $background-orange-light;
  }

  &__label {
    margin-left: 0.12rem;
    font-size: 0.16rem;
    font-family: 'Montserrat', sans-serif;
  }

  $icon-size: 0.32rem;
  &__icon {
    width: $icon-size;
    height: $icon-size;
  }
}
</style>
